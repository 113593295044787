import "styles/pages/single-post.scss";

import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import PostBanner from "components/PostBanner";
import TitleWithLine from "components/TitleWithLine";
import NewsTile from "components/NewsTile";

const SingleNews = ({ pageContext, data }) => {
  const newsPosts = data.newsPagePosts.nodes.filter((node) => {
    return node.id !== pageContext.id;
  });
  const news = pageContext.post;

  return (
    <Layout>
      <Seo
        title={pageContext?.title}
        description={
          news?.acfBlogNews?.seoMetaDescription
            ? news?.acfBlogNews?.seoMetaDescription
            : pageContext?.seo?.description
        }
      />
      <PostBanner
        title={news.title}
        className="custom-post"
        date={news.date}
        parentLink="/news/"
      />
      <section className="post">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <img
                src={news.featuredImage?.node.sourceUrl}
                alt=""
                className="post__img"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="post__content"
                dangerouslySetInnerHTML={{ __html: news.content }}
              />
              <div className="post__nav">
                {pageContext?.prev && (
                  <Link
                    to={pageContext?.prev?.uri}
                    className="btn btn--white btn--reversed"
                  >
                    Previous news
                  </Link>
                )}
                {pageContext?.next && (
                  <Link to={pageContext?.next?.uri} className="btn btn--white">
                    Next news
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {news?.author?.node?.acfAuthor?.authorName && (
        <section className="post-author">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-2">
                {news?.author?.node?.acfAuthor?.authorImage?.localFile
                  ?.publicURL && (
                  <img
                    className="img-fluid post-author__image"
                    src={
                      news?.author?.node?.acfAuthor?.authorImage?.localFile
                        ?.publicURL
                    }
                    alt=""
                  />
                )}
                <div className="post-author__social">
                  {news?.author?.node?.acfAuthor?.authorLinkedin && (
                    <a
                      href={news?.author?.node?.acfAuthor?.authorLinkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          require("../assets/icons/post-author-linkedin.svg")
                            .default
                        }
                        alt=""
                      />
                    </a>
                  )}
                  {news?.author?.node?.acfAuthor?.authorEmail && (
                    <a
                      href={`mailto:${news?.author?.node?.acfAuthor?.authorEmail}`}
                    >
                      <img
                        src={
                          require("../assets/icons/post-author-mail.svg")
                            .default
                        }
                        alt=""
                      />
                    </a>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <h4>About author</h4>
                <h2>{news?.author?.node?.acfAuthor?.authorName}</h2>
                <h5>{news?.author?.node?.acfAuthor?.authorPosition}</h5>
                {news?.author?.node?.acfAuthor?.authorDescription && (
                  <p>{news?.author?.node?.acfAuthor?.authorDescription}</p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      {newsPosts.length > 0 && (
        <section className="featured-news">
          <div className="container">
            <TitleWithLine variantX="left" variantY="bottom">
              Featured news
            </TitleWithLine>
            <div className="row section-news__row">
              {newsPosts?.slice(0, 3).map((nodes, index) => {
                const item = nodes;
                return (
                  <div
                    className="col-lg-4 col-md-6 section-news__tile"
                    key={index}
                  >
                    <NewsTile
                      link={item.uri}
                      image={item.featuredImage?.node.sourceUrl}
                      date={item.date}
                      title={item.title}
                      desc={item.newsPostShortDescription.shortDescription}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    newsPagePosts: allWpPost(limit: 4) {
      nodes {
        content
        date(formatString: "D MMMM YYYY")
        featuredImage {
          node {
            sourceUrl
          }
        }
        id
        slug
        seo {
          metaDesc
          title
        }
        title
        uri
        newsPostShortDescription {
          shortDescription
        }
        language {
          name
          locale
          code
          slug
        }
      }
    }
  }
`;

export default SingleNews;
