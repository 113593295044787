import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import theme from "theme";

import PinkArrow from "../../assets/icons/btn-arrow-pink.svg";

const Tile = styled.div`
  background-color: #fff;
  transition: all 0.25s;
  box-shadow: ${theme.shadows.shadow};
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 4px;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: ${theme.colors.pink};
    transition: all 0.25s ease-in-out;
  }
  ${theme.mixins.onEvent} {
    &:after {
      width: 100%;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  max-height: 225px;
  border-radius: 4px;
  cursor: pointer;
  object-fit: cover;
  ${theme.screens.mdScreen} {
    max-height: unset;
  }
`;

const TextContainer = styled.div`
  margin-bottom: 40px;
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const DateText = styled.div`
  color: #cccccc;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const TitleText = styled.h3`
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 18px;
`;

const ReadMoreBtn = styled.div`
  position: absolute;
  left: 3%;
  bottom: 3%;
  color: ${theme.colors.pink};
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    right: -20px;
    top: 52%;
    transform: translate(0, -50%);
    background-image: url(${PinkArrow});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;
    width: 15px;
    height: 10px;
    transition: all 0.25s ease-in-out;
    filter: brightness(1) invert(0);
  }
  ${theme.mixins.onEvent} {
    &:after {
      right: -30px;
    }
  }
`;

const NewsTile = ({ link, image, date, title, desc }) => {
  // const [view, setView] = useState(250);

  // useEffect(() => {
  //   const width = window.innerWidth;

  //   if (width >= 1440 && width < 1536) {
  //     setView(130);
  //   } else if (width < 1440 && width > 992) {
  //     setView(140);
  //   } else if (width <= 992 && width > 768) {
  //     setView(110);
  //   } else if (width <= 768 && width > 578) {
  //     setView(190);
  //   } else if (width <= 578 && width > 370) {
  //     setView(140);
  //   } else if (width <= 370) {
  //     setView(120);
  //   } else {
  //     setView(250);
  //   }
  // }, [view]);
  return (
    <Tile>
      <Link to={link}>
        <Image src={image} className="img-fluid" alt="" />
      </Link>

      <TextContainer>
        <DateText>{date}</DateText>
        <Link to={link}>
          <TitleText>{title}</TitleText>
        </Link>
        <p>{desc}</p>
      </TextContainer>
      <Link to={link}>
        <ReadMoreBtn>Read more</ReadMoreBtn>
      </Link>
    </Tile>
  );
};

export default NewsTile;
